<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="none">
        <path fill="url(#paint0_linear)"
              d="M13.738.627L.627 13.727a1.797 1.797 0 000 2.546l13.111 13.1a1.8 1.8 0 002.549 0l5.497-5.492a1.614 1.614 0 000-2.284 1.617 1.617 0 00-2.286 0L15.33 25.76a.428.428 0 01-.617 0L4.243 15.299a.427.427 0 010-.617l10.47-10.461a.428.428 0 01.617 0l4.168 4.164c.631.63 1.655.63 2.286 0a1.614 1.614 0 000-2.284L16.287.608a1.818 1.818 0 00-2.549.019z"/>
        <path fill="url(#paint1_linear)"
              d="M13.738.627L.627 13.727a1.797 1.797 0 000 2.546l13.111 13.1a1.8 1.8 0 002.549 0l5.497-5.492a1.614 1.614 0 000-2.284 1.617 1.617 0 00-2.286 0L15.33 25.76a.428.428 0 01-.617 0L4.243 15.299a.427.427 0 010-.617l10.47-10.461c.437-.377 1.15-1.14 2.197-1.318.777-.133 1.628.16 2.55.875L16.288.608a1.818 1.818 0 00-2.549.019z"/>
        <path fill="url(#paint2_linear)"
              d="M23.053 20.378c.631.631 1.655.631 2.286 0l4.055-4.052a1.797 1.797 0 000-2.546l-4.09-4.072a1.62 1.62 0 00-2.289.002 1.614 1.614 0 000 2.284l2.763 2.76a.427.427 0 010 .617l-2.725 2.723a1.614 1.614 0 000 2.284z"/>
        <path fill="url(#paint3_linear)"
              d="M15.078 18.603a3.539 3.539 0 003.54-3.537 3.539 3.539 0 00-3.54-3.537 3.539 3.539 0 00-3.54 3.537 3.539 3.539 0 003.54 3.537z"/>
        <defs>
            <linearGradient id="paint0_linear"
                            x1="13.86"
                            x2="26.319"
                            y1=".096"
                            y2="7.705"
                            gradientUnits="userSpaceOnUse">
                <stop stop-color="#4285EB"/>
                <stop offset="1" stop-color="#2EC7FF"/>
            </linearGradient>
            <linearGradient id="paint1_linear"
                            x1="15.531"
                            x2="9.377"
                            y1=".096"
                            y2="31.901"
                            gradientUnits="userSpaceOnUse">
                <stop stop-color="#29CDFF"/>
                <stop offset=".379" stop-color="#148EFF"/>
                <stop offset="1" stop-color="#0A60FF"/>
            </linearGradient>
            <linearGradient id="paint2_linear"
                            x1="27.686"
                            x2="19.679"
                            y1="7.729"
                            y2="20.251"
                            gradientUnits="userSpaceOnUse">
                <stop stop-color="#FA816E"/>
                <stop offset=".415" stop-color="#F74A5C"/>
                <stop offset="1" stop-color="#F51D2C"/>
            </linearGradient>
            <linearGradient id="paint3_linear"
                            x1="16.361"
                            x2="13.697"
                            y1="9.004"
                            y2="19.661"
                            gradientUnits="userSpaceOnUse">
                <stop stop-color="#FA8E7D"/>
                <stop offset=".513" stop-color="#F74A5C"/>
                <stop offset="1" stop-color="#F51D2C"/>
            </linearGradient>
        </defs>
    </svg>
</template>
