<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="78" height="18" fill="none">
        <g>
            <path fill="#000" fill-rule="evenodd" d="M30.366 13.029L29.3 17.786h-2.442L30.898.47h2.886l3.818 17.315h-2.486l-1.021-4.8h-3.73v.043zm3.462-1.929l-.843-4.157A121.75 121.75 0 0132.319 3h-.089c-.177 1.243-.444 2.871-.71 3.943l-.844 4.157h3.152zM46.969 0v14.571c0 .943.044 2.358.089 3.215h-2.176l-.177-1.5h-.09c-.443.857-1.42 1.714-2.707 1.714-2.397 0-3.862-2.529-3.862-6.386 0-4.414 1.953-6.514 3.995-6.514 1.021 0 1.865.471 2.309 1.414h.044V0h2.575zm-2.486 10.071c0-.214 0-.514-.045-.771-.133-1.114-.799-2.1-1.687-2.1-1.553 0-2.086 2.1-2.086 4.414 0 2.572.71 4.372 2.042 4.372.577 0 1.287-.3 1.731-1.757.045-.215.09-.515.09-.815v-3.343h-.045zM52.962 18c-2.575 0-4.44-2.143-4.44-6.428 0-4.543 2.22-6.472 4.573-6.472 2.575 0 4.395 2.229 4.395 6.429C57.49 16.5 54.96 18 52.962 18zm.044-1.886c1.554 0 1.91-2.657 1.91-4.543 0-1.885-.356-4.542-1.954-4.542-1.643 0-1.954 2.657-1.954 4.543 0 2.057.356 4.542 1.998 4.542zM59 0h2.485v6.729h.045c.666-1.115 1.554-1.629 2.708-1.629 2.22 0 3.596 2.4 3.596 6.257 0 4.457-1.91 6.643-4.04 6.643-1.287 0-1.998-.686-2.575-1.757h-.089l-.133 1.586h-2.175c.044-.858.089-2.272.089-3.215V0h.088zm2.485 13.371c0 .3.045.558.09.772.399 1.5 1.153 1.843 1.686 1.843 1.51 0 2.042-1.886 2.042-4.457 0-2.4-.577-4.329-2.086-4.329-.844 0-1.51.943-1.687 1.843-.045.257-.089.6-.089.857v3.471h.044zM71.563 12.043c.044 3.214 1.465 4.028 2.974 4.028.888 0 1.687-.214 2.22-.471l.355 1.757c-.754.386-1.953.6-3.107.6-3.241 0-4.972-2.357-4.972-6.214 0-4.072 1.953-6.6 4.616-6.6 2.709 0 3.951 2.486 3.951 5.614 0 .6-.044.986-.044 1.329l-5.993-.043zm3.729-1.714c.044-2.229-.755-3.386-1.776-3.386-1.287 0-1.909 1.843-1.953 3.386h3.729z" clip-rule="evenodd"/>
        </g>
        <g>
            <path fill="#ED1C2E" fill-rule="evenodd" d="M12.741.514h7.503v17.272L12.74.514zM7.503.514H0v17.272L7.503.514zM10.122 6.857l4.75 10.929h-3.108l-1.42-3.472H6.837l3.285-7.457z" clip-rule="evenodd"/>
        </g>
        <g>
            <path fill="#000" fill-rule="evenodd" d="M77.467 5.057c.311 0 .577.257.577.557 0 .3-.266.557-.577.557-.31 0-.577-.257-.577-.557 0-.3.266-.557.577-.557zm0 .086c-.266 0-.444.214-.444.471s.222.472.444.472c.267 0 .444-.215.444-.472s-.177-.471-.444-.471zm-.133.814h-.089v-.6h.178c.089 0 .177 0 .177.043.045.043.045.086.045.129 0 .085-.045.128-.133.171.044 0 .088.043.088.171 0 .086.045.129.045.172h-.089s-.044-.086-.044-.172c0-.085-.045-.128-.133-.128h-.09v.214h.045zm0-.343h.089c.089 0 .177-.043.177-.085 0-.043-.044-.129-.177-.129h-.089v.214zM21.22.514c.31 0 .577.258.577.558 0 .3-.266.557-.577.557-.31 0-.577-.257-.577-.557 0-.3.266-.558.577-.558zm0 .086c-.266 0-.444.214-.444.472 0 .257.222.471.444.471.267 0 .444-.214.444-.471 0-.258-.177-.472-.444-.472zm-.133.772h-.089v-.6h.178c.089 0 .177 0 .177.042.045.043.045.086.045.129 0 .086-.045.129-.133.171.044 0 .088.043.088.172 0 .086.045.128.045.171h-.089s-.044-.085-.044-.171c0-.086-.045-.129-.134-.129h-.088v.215h.044zm0-.343h.089c.089 0 .177-.043.177-.086s-.044-.129-.177-.129h-.089v.215z" clip-rule="evenodd"/>
        </g>
    </svg>
</template>
