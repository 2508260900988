import { withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "filter-button" }
const _hoisted_2 = { class: "text-sm font-medium" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DownCaret = _resolveComponent("DownCaret")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isOpen)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "fixed w-screen h-screen top-0 left-0",
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.closeMenu && _ctx.closeMenu(...args)), ["self"]))
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "h-8 rounded-full px-4 flex items-center justify-between bg-black text-white z-20 relative whitespace-nowrap",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isOpen = !_ctx.isOpen))
    }, [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.selectedFilter.title), 1),
      _createVNode(_component_DownCaret, { upwards: _ctx.isOpen }, null, 8, ["upwards"])
    ]),
    (_ctx.isOpen && _ctx.filters)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["filter-button__dropdown", { '--open': _ctx.isOpen }])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filters, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "px-4 py-1",
              onClick: ($event: any) => (_ctx.setSelectedFilter(item))
            }, [
              _createElementVNode("p", {
                class: _normalizeClass(["text-sm", { 'text-blue-400': _ctx.selectedFilter.key === item.key }])
              }, _toDisplayString(item.title), 3)
            ], 8, _hoisted_3))
          }), 128))
        ], 2))
      : _createCommentVNode("", true)
  ]))
}