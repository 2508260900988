

    import { defineComponent } from 'vue'
    import Column from './Grid/Column.vue'
    import Grid from './Grid/Grid.vue'
    import Container from './Container.vue'
    import OpenSourcePackageSkeleton from './OpenSourcePackageSkeleton.vue'

    export default defineComponent({
        name: 'OpenSourceSkeleton',
        components: {
            Grid,
            Container,
            Column,
            OpenSourcePackageSkeleton
        }
    })

