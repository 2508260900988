<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="76" height="24" fill="none">
        <g>
            <path fill="#4285F4"
                  d="M18.683 8.477H9.741v2.537h6.369c-.308 3.551-3.38 5.068-6.277 5.068-3.71 0-6.944-2.811-6.944-6.747 0-3.84 3.08-6.792 6.952-6.792 2.98 0 4.74 1.835 4.74 1.835l1.843-1.835S14.058.006 9.75.006C4.249-.002 0 4.459 0 9.283c0 4.728 3.995 9.33 9.88 9.33 5.177 0 8.964-3.419 8.964-8.464 0-1.058-.16-1.68-.16-1.672z"/>
        </g>
        <g>
            <path fill="#EA4335"
                  d="M26.258 6.636c-3.642 0-6.246 2.738-6.246 5.934 0 3.24 2.528 6.037 6.292 6.037 3.403 0 6.192-2.508 6.192-5.963 0-3.966-3.25-6.008-6.238-6.008zm.038 2.353c1.79 0 3.488 1.39 3.488 3.64 0 2.197-1.69 3.632-3.495 3.632-1.982 0-3.55-1.531-3.55-3.647 0-2.079 1.544-3.625 3.557-3.625z"/>
        </g>
        <g>
            <path fill="#FBBC05"
                  d="M39.77 6.636c-3.64 0-6.245 2.738-6.245 5.934 0 3.24 2.528 6.037 6.292 6.037 3.403 0 6.192-2.508 6.192-5.963-.008-3.966-3.25-6.008-6.238-6.008zm.031 2.353c1.79 0 3.488 1.39 3.488 3.64 0 2.197-1.69 3.632-3.495 3.632-1.982 0-3.55-1.531-3.55-3.647 0-2.079 1.545-3.625 3.557-3.625z"/>
        </g>
        <g>
            <path fill="#4285F4"
                  d="M53.092 6.644c-3.342 0-5.97 2.818-5.97 5.977 0 3.603 3.043 5.993 5.908 5.993 1.775 0 2.712-.68 3.411-1.457v1.183c0 2.064-1.298 3.3-3.265 3.3-1.897 0-2.85-1.361-3.18-2.13l-2.39.96c.846 1.725 2.551 3.523 5.593 3.523 3.32 0 5.854-2.013 5.854-6.237V6.858H56.45V8.02c-.807-.829-1.905-1.376-3.357-1.376zm.246 2.345c1.636 0 3.318 1.346 3.318 3.647 0 2.338-1.674 3.625-3.357 3.625-1.782 0-3.434-1.39-3.434-3.603-.007-2.293 1.713-3.67 3.473-3.67z"/>
        </g>
        <g>
            <path fill="#34A853" d="M63.34.621h-2.55V18.26h2.55V.62z"/>
        </g>
        <g>
            <path fill="#EA4335"
                  d="M70.4 6.629c-3.15 0-5.793 2.412-5.793 5.978 0 3.765 2.95 6.007 6.1 6.007 2.627 0 4.24-1.384 5.209-2.627l-2.151-1.376c-.561.836-1.49 1.65-3.043 1.65-1.743 0-2.55-.925-3.05-1.812l8.336-3.33-.43-.976c-.815-1.916-2.69-3.514-5.178-3.514zm.107 2.308c1.137 0 1.952.584 2.297 1.28l-5.562 2.242c-.238-1.74 1.468-3.522 3.265-3.522z"/>
        </g>
    </svg>
</template>
