

    import { defineComponent, PropType, ref } from 'vue'
    import { useTranslation } from '../compositions/UseTranslation'
    import DownCaret from './Icons/DownCaret.vue'
    import { FilterInterface } from '../Interfaces/FilterInterface'

    export default defineComponent({
        name: 'FilterButton',
        components: {
            DownCaret
        },
        props: {
            filters: {
                type: Array as PropType<any[]>, required: true
            }
        },
        setup(props, { emit }) {

            const { currentLocale } = useTranslation()
            const isOpen = ref(false)
            const selectedFilter = ref(props.filters[0])

            return {

                currentLocale,
                selectedFilter,
                isOpen,
                setSelectedFilter(filter: FilterInterface) {

                    selectedFilter.value = filter
                    isOpen.value = false
                    emit('change', filter.key)

                },
                closeMenu() {

                    isOpen.value = false

                }

            }

        }
    })

